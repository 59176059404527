import $axios from 'utils/axios'

const article = {
  getNewsListApi: async (payload) => {
    const { data } = await $axios({
      method: 'post',
      url: '/article/newsList',
      data: {
        categoryID: payload.categoryID ?? 15,
        offset: payload.offset ?? 0,
        limit: payload.limit ?? 5,
      },
    })
    return data
  },
  getNewsApi: async (payload) => {
    const { data } = await $axios({
      method: 'post',
      url: '/article/news',
      data: {
        id: payload.id ?? 0,
      }
      ,
    })
    return data
  },
  getGameInfoApi: async (payload) => {
    const { data } = await $axios({
      method: 'get',
      url: `/article/gameInfo/${payload}`,
    })
    return data
  },
  getServiceClauseApi: async (payload) => {
    const { data } = await $axios({
      method: 'post',
      url: '/article/serviceClause',
    })
    return data
  },
  getPrivacyClauseApi: async (payload) => {
    const { data } = await $axios({
      method: 'post',
      url: '/article/privacyClause',
    })
    return data
  },
  getGameRuleApi: async (payload) => {
    const { data } = await $axios({
      method: 'post',
      url: '/article/gameRule',
    })
    return data
  },
  getQuestionApi: async (payload) => {
    const { data } = await $axios({
      method: 'post',
      url: '/article/question',
    })
    return data
  },
  getAntiFraudApi: async (payload) => {
    const { data } = await $axios({
      method: 'post',
      url: '/article/antiFraud',
    })
    return data
  },
  getVipIntroApi: async (payload) => {
    const { data } = await $axios({
      method: 'post',
      url: '/article/vipIntro',
    })
    return data
  },
  getGiftIntroApi: async (payload) => {
    const { data } = await $axios({
      method: 'post',
      url: '/article/giftIntro',
    })
    return data
  },
  getActivityIntroApi: async (payload) => {
    const { data } = await $axios({
      method: 'post',
      url: '/article/activityIntro',
    })
    return data
  },
  getPropsIntroApi: async (payload) => {
    const { data } = await $axios({
      method: 'post',
      url: '/article/propsIntro',
    })
    return data
  },
  getMascotIntroApi: async (payload) => {
    const { data } = await $axios({
      method: 'post',
      url: '/article/mascotIntro',
    })
    return data
  },
  getPageApi: async (payload) => {
    const { data } = await $axios({
      method: 'post',
      url: `/article/${payload}`,
    })
    return data
  },
  getDeregistrationApi: async (payload) => {
    const { data } = await $axios({
      method: 'post',
      url: '/article/deregistration',
    })
    return data
  },
  getFbCustomerApi: async (payload) => {
    const { data } = await $axios({
      method: 'post',
      url: '/article/fbCustomer',
    })
    return data
  },
  getProbabilityDetailApi: async (payload) => {
    const { data } = await $axios({
      method: 'post',
      url: '/probabilityInfo/list',
      data: payload,
    })
    return data
  },
  getFixedArticleApi: async (payload) => {
    const { data } = await $axios({
      method: 'post',
      url: '/article/fixedArticle',
      data: payload,
    })
    return data
  },
}
export const {
  getNewsListApi,
  getNewsApi,
  getGameInfoApi,
  getServiceClauseApi,
  getGameRuleApi,
  getPrivacyClauseApi,
  getQuestionApi,
  getAntiFraudApi,
  getVipIntroApi,
  getGiftIntroApi,
  getActivityIntroApi,
  getPropsIntroApi,
  getMascotIntroApi,
  getPageApi,
  getDeregistrationApi,
  getFbCustomerApi,
  getProbabilityDetailApi,
  getFixedArticleApi,
} = article
export default article
