import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useStore from 'zustand/dialog'
import EighteenPlus from 'image/Rate18+.png'
import RelatedLinks from 'components/relatedLinks/common'
import getServerStatusService from 'services/system/getServerStatus'
import Login from 'views/login/common/login'
import { useLocation } from 'react-router-dom'
import styles from './footer.module.sass'
import footerInfo from '../common/variables'

const styledRoot = pathname => ({
  backgroundColor: ['/', '/news', '/gameIntro', '/mascotIntro', '/propsIntro', '/giftIntro', '/vipIntro', '/activityIntro', '/deposit', '/passbook', '/modifyPassword', '/rank', '/question', '/suspenseList', '/rightsDescription', '/serviceDetail', '/probabilityDetail'].includes(pathname) ? '#4C0800' : 'transparent',
})

function Footer() {
  const [isMaintaining, setIsMaintaining] = useState(false)
  const [counter, setCounter] = useState(0)
  const { setDialog } = useStore()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const { isLoggedIn } = useSelector(state => state.auth)

  const count = useCallback(() => {
    if (isMaintaining && !isLoggedIn && counter < 15) {
      setCounter(counter + 1)
    }
  }, [counter, isLoggedIn, isMaintaining])

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await dispatch(getServerStatusService())
        // 0：正常營運、1：維護中
        setIsMaintaining(data)
      } catch (error) {
        //
      }
    }
    fetchData()
  }, [dispatch])

  useEffect(() => {
    if (counter === 15) {
      setDialog({
        open: true,
        component: () => (<Login passCheckMaintain />),
      })
    }
  }, [counter, setDialog])

  return (
    <div
      className={styles.root}
      style={styledRoot(pathname)}
    >
      <div className={styles.leftTrack} />
      <div className={styles.centerContent}>
        <div className={styles.vendor} />
        <div className={styles.email}>
          {footerInfo.email}
        </div>
        <div className={styles.footerInfo}>
          <div className={styles.announcement}>
            {footerInfo.announcement}
            <div className={styles.reCaptchaInfo}>
              This site is protected by reCAPTCHA and the Google
              <a href="https://policies.google.com/privacy">
                {' '}
                Privacy Policy
                {' '}
              </a>
              and
              <a href="https://policies.google.com/terms">
                {' '}
                Terms of Service
                {' '}
              </a>
              apply.
            </div>
          </div>
          <div className={styles.relatedLinks}>
            <RelatedLinks />
          </div>
          <div
            role="button"
            tabIndex={0}
            className={styles.rating}
            title="限制級 18+"
            onClick={count}
            onKeyPress={count}
          >
            <img src={EighteenPlus} alt="限制級 18+" />
          </div>
        </div>

        <div className={styles.copyRight}>
          {footerInfo.copyRight}
        </div>
      </div>
      <div className={styles.rightTrack} />
    </div>
  )
}
export default Footer
